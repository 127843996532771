<template>
  <!--Header-->
    <div class="pt-12 pb-8 grid justify-items-stretch">
      
      
        <div class="justify-self-center relative"> 
          <h1 class="text-3xl font-bold dark:text-gray-200 relative z-10">What we do</h1>
          <span class="absolute w-120 h-3 bg-yellow-100 bottom-0.5 -ml-10 dark:bg-gray-600"></span>
      </div>
    </div>
    <div v-for="(service, index) in services" :key="index">
      <div v-if="index == 0">
        <Card side="left" :title="service.title" :content="service.short_content" :link="'services/' + service.meta.slug" :img="service.main_image" />
      </div>
      <div v-else-if="index == 1">
        <Card side="right" :title="service.title" :content="service.short_content" :link="'services/' + service.meta.slug" :img="service.main_image" />
      </div>
      <div v-else-if="index == 2">
        <Card side="left2" :title="service.title" :content="service.short_content" :link="'services/' + service.meta.slug" :img="service.main_image" />
      </div>
      <div v-else>
        <Card side="right2" :title="service.title" :content="service.short_content" :link="'services/' + service.meta.slug" :img="service.main_image" />
      </div>
    </div>
    

</template>

<script>
import Card from '@/components/Shared/Card.vue'

export  default {
  name: 'WhatWeDo',
  components: {
    Card,
  },
  props: [ 'services', ],
  data() {
    return {
      cardSide: "left",
    }
  },
  
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
