<template>
  <main>
    <div v-if="loading" class="h-screen flex justify-center items-center">
      <div style="border-top-color:transparent"
        class="w-16 h-16 border-4 border-green-400 border-dotted rounded-full animate-spin"></div>
    </div>
    <div v-else>
      <Hero :green_title="hero[0].hero_green_title" :black_title="hero[0].hero_black_title" :content="hero[0].hero_content" :links="hero[0].hero_links" />
      <WhatWeDo :services="services" />
      <FeaturedProject :portfolio="portfolio[0]" />
      <WhoWeAre :content="about[0].short_content" :techTitle="about[0].tech_title" :techIcons="about[0].tech_icons" />
      <EstimateProject />
    </div>
    
  </main>
    
</template>

<script>
// @ is an alias to /src
import Hero from '@/components/Home/Hero.vue'
import WhatWeDo from '@/components/Shared/WhatWeDo.vue'
import FeaturedProject from '@/components/Shared/FeaturedProject.vue'
import WhoWeAre from '@/components/Home/WhoWeAre.vue'
import EstimateProject from '@/components/Shared/EstimateProject.vue'

import serverAddress from '@/mixins/serverAddress';
import titleMixin from '@/mixins/titleMixin'
// import descriptionMixin from '@/mixins/descriptionMixin'

export default {
  title: 'Mobile application development company | Flexisoft',
  // description: "App development company from Poland specialized in mobile apps for iOS, Android, web apps and more", 
  name: 'Home',
  mixins: [serverAddress, titleMixin], //descriptionMixin
  components: {
    Hero,
    WhatWeDo,
    FeaturedProject,
    WhoWeAre,
    EstimateProject
  },
  data() {
    return {
      loading: true,
      services: [],
      hero: [],
      portfolio: [],
      about: [],
      description: '',
    }
  },
  methods: {
    async getServices() {
      const res = await fetch(this.serverAddress +  '/api/v2/pages/?type=services.Service&fields=*&limit=3')
      const data = await res.json()
      return data
    },
    async getHomeHero() {
      const res = await fetch(this.serverAddress +  '/api/v2/pages/?type=home.HomePage&fields=*')
      const data = await res.json()
      return data
    },
    async getPortfolio() {
      // random and featured
      const res = await fetch(this.serverAddress +  '/api/v2/pages/?type=portfolio.Portfolio&fields=*&order=random&limit=1&featured=true')
      const data = await res.json()
      return data
    },
    async getAbout() {
      const res = await fetch(this.serverAddress +  '/api/v2/pages/?type=about.About&fields=*')
      const data = await res.json()
      return data
    },
  },
  async created() {
    // this.titleMixin = "asfdsfsdfsfd"
    
    const data = await this.getServices()
    this.services = data.items
    
    const heroData = await this.getHomeHero()
    this.hero = heroData.items

    const portfolioData = await this.getPortfolio()
    this.portfolio = portfolioData.items
   
    const aboutData = await this.getAbout()
    this.about = aboutData.items

    this.loading = false

    document.querySelector('meta[name="description"]').setAttribute("content", this.hero[0].meta.search_description );
  }
}
</script>
