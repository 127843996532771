<template>
<!-- left 1 -->
<div v-if="side == 'left'"> 
    <div class="container px-3 mx-auto flex flex-wrap flex-col md:flex-row items-center">
        <!--Left Col-->
        <div class="flex flex-col w-full md:w-7/12 justify-center items-start 
        bg-gradient-to-br from-green-500 to-green-600 text-white p-10 pb-12 rounded-lg transform rotate-1 shadow-xl">
          <div class="transform -rotate-1">
              <h2 class="text-2xl pb-2 font-bold z-50">{{ title }}</h2>
              <div v-html="content"></div>
              <div v-if="link" class="flex justify-end pt-2">
                  <button class="flex items-center transform transition hover:scale-105 duration-200">
                      <a class="font-semibold text-white" :href="link">learn more</a>
                      <span class="material-icons-outlined text-base">arrow_right_alt</span>
                  </button>
              </div>
          </div>
            
        </div>
        <div v-if="link" class="flex flex-col w-full md:w-5/12 py-6 justify-center items-center">
          <a :href="link">
            <img :src="img" alt="img" class="w-64 md:w-72 cursor-pointer transform transition hover:scale-105 duration-200">
          </a>
        </div>
        <div v-else class="flex flex-col w-full md:w-5/12 py-6 justify-center items-center">
          <img :src="img" alt="img" class="w-64 md:w-72">
        </div>
      </div>
    
    <!-- Card item -->
</div>
<div v-else-if="side == 'right'">
  <div class="-mt-1 relative z-10 container px-3 mx-auto flex flex-col-reverse  flex-wrap flex-col md:flex-row items-center">
        <!--Left Col-->
        <div v-if="link"  class="flex flex-col w-full md:w-5/12 py-6 justify-center items-center">
          <a :href="link">
            <img :src="img" alt="img" class="w-64 md:w-72 cursor-pointer transform transition hover:scale-105 duration-200">
          </a>
        </div>
        <div v-else class="flex flex-col w-full md:w-5/12 py-6 justify-center items-center">
          <img :src="img" alt="img" class="w-64 md:w-72">
        </div>
        <div class="flex flex-col w-full md:w-7/12 justify-center items-start
        bg-gradient-to-br from-blue-400 to-blue-500 text-white p-10 pt-12 pb-12 rounded-lg transform rotate-2 shadow-xl">
        <!-- <div class="flex flex-col w-full md:w-7/12 justify-center items-start
        bg-gradient-to-br from-blue-400 to-indigo-500 text-white p-10 pt-12 pb-12 rounded-lg transform rotate-2 shadow-xl"> -->
        
          <div class="transform -rotate-2">
            <h2 class="text-2xl pb-2 font-bold z-50">{{ title }}</h2>
            <div v-html="content"></div>
            <div v-if="link" class="flex justify-end pt-2">
                  <button class="flex items-center transform transition hover:scale-105 duration-200">
                      <a class="font-semibold text-white" :href="link">learn more</a>
                      <span class="material-icons-outlined text-base">arrow_right_alt</span>
                  </button>
            </div>
          </div>
        </div>
        
      </div>
</div>
<!-- left 2 -->
<div v-else-if="side == 'left2'">
  <div class="-mt-2 container px-3 mx-auto flex flex-wrap flex-col md:flex-row items-center">
        <!--Left Col-->
        <div class="flex flex-col w-full md:w-7/12 justify-center items-start
        bg-gradient-to-br from-gray-500 to-gray-600 text-white p-10 pb-12 rounded-lg transform -rotate-1 shadow-xl">
          <div class="transform rotate-1">
            <h2 class="text-2xl pb-2 font-bold">{{ title }}</h2>
            <div v-html="content"></div>
             <div v-if="link" class="flex justify-end pt-2">
                  <button class="flex items-center transform transition hover:scale-105 duration-200">
                      <a class="font-semibold text-white" :href="link">learn more</a>
                      <span class="material-icons-outlined text-base">arrow_right_alt</span>
                  </button>
            </div>
          </div>
        </div>
        <div v-if="link"  class="flex flex-col w-full md:w-5/12 pt-6 justify-center items-center">
          <a :href="link">
            <img :src="img" alt="img" class="w-64 md:w-72 cursor-pointer transform transition hover:scale-105 duration-200">
          </a>
        </div>
        <div v-else class="flex flex-col w-full md:w-5/12 pt-6 justify-center items-center">
          <img :src="img" alt="img" class="w-64 md:w-72">
        </div>
      </div>
</div>
<div v-else>
      <div class="-mt-1 relative z-10 container px-3 mx-auto flex flex-col-reverse  flex-wrap flex-col md:flex-row items-center">
        <!--Left Col-->
        <div v-if="link"  class="flex flex-col w-full md:w-5/12 py-6 justify-center items-center">
          <a :href="link">
            <img :src="img" alt="img" class="w-64 md:w-72 cursor-pointer transform transition hover:scale-105 duration-200">
          </a>
        </div>
        <div v-else class="flex flex-col w-full md:w-5/12 py-6 justify-center items-center">
          <img :src="img" alt="img" class="w-64 md:w-72">
        </div>
        <div class="flex flex-col w-full md:w-7/12 justify-center items-start
        bg-gradient-to-br from-yellow-400 to-yellow-500 text-white p-10 pt-12 pb-12 rounded-lg transform rotate-2 shadow-xl">
        
        
          <div class="transform -rotate-2">
            <h2 class="text-2xl pb-2 font-bold z-50">{{ title }}</h2>
            <div v-html="content"></div>
            <div v-if="link" class="flex justify-end pt-2">
                  <button class="flex items-center transform transition hover:scale-105 duration-200">
                      <a class="font-semibold text-white" :href="link">learn more</a>
                      <span class="material-icons-outlined text-base">arrow_right_alt</span>
                  </button>
            </div>
          </div>
        </div>
        
      </div>
</div>
</template>

<script>
export default {
  name: 'Card',
  props: [ 'side', 'title', 'content','link', 'img']
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>