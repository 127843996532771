export default {
  data: function() {
    return {
      get serverAddress() {
        // return "http://localhost:8000"
        return "https://flexiback.flexisoft.usermd.net"
        // return "https://b48d6020407c.ngrok.io"
      }
    }
  }
};
