<template>
  <!--Footer-->
    <div class="flex w-full mx-auto px-2 py-4 sm:px-6 lg:px-6 bg-gray-100 dark:bg-gray-700">
      <div class="relative flex w-full items-center justify-between text-dark dark:text-gray-50">
        <div class="flex-grow">
          <div class="flex flex-col md:flex-row md:space-x-8 pt-2 font-semibold">
            <div class="flex items-center">
              <a href="/services/mobile-application-development" class="transform transition hover:scale-105 duration-200">Mobile apps</a>
            </div>
            <div class="flex items-center">
              <a href="/services/web-apps" class="transform transition hover:scale-105 duration-200">Web apps</a>
            </div>
            <div class="flex items-center">
              <a href="/services/uxui-services" class="transform transition hover:scale-105 duration-200">UX/UI</a>
            </div>
          </div>  
        </div>
        <div class="flex-grow text-right">
          <h5 class="font-normal text-md">© Flexisoft</h5>
          <p class="text-xs pt-2 text-gray-300">Illustrations by <a href="https://storyset.com" target="_blank">Storyset</a></p>
        </div>
      </div>
    </div>
  <div v-if="cookies == false" class="flex sticky bottom-0 z-50 left-0 flex-col md:flex-row mx-auto items-center px-2 py-4 sm:px-6 lg:px-6 bg-gray-400 dark:bg-gray-800">
    <div class="w-full md:w-10/12 text-white">
      <h3 class="text-base">This site use cookies</h3>
      <p class="text-xs pr-4">We use information saved using cookies to ensure maximum convenience in using our website. They can also be used by research and advertising companies cooperating with us. If you agree to save the information contained in cookies, click on accept button. If you do not agree, you can change cookie settings in your browser. </p>
    </div>
    <div class="w-full md:w-2/12">
      <button @click="acceptCookies" class="bg-mygreen flex align-center text-white font-bold rounded-full py-2 px-12 shadow-lg focus:outline-none focus:shadow-outline transform transition hover:scale-105 duration-300 ease-in-out">
        Accept
      </button>
    </div>
  </div>

   

</template>

<script>
export default {
  name: 'Footer',
  data() {
    return {
      cookies: false,
    }
  },
  mounted() {
    const userCookie = localStorage.getItem('user-cookie')
    
    if (userCookie == 'yes' ) {
      this.cookies = true
    } else {
      this.cookies = false
    }
    
  },
  methods: {
    acceptCookies() {
      this.cookies = true
      localStorage.setItem("user-cookie", 'yes');
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
