<template>
  
  <TopNav />
    
  <!-- <div class="container"> -->
  <div class="container max-w-none dark:bg-gray-700"> 
    <router-view/>
  </div>

  <Footer />
  <!-- <CookieConsent></CookieConsent> -->
  <!-- <CookieBox></CookieBox> -->
</template>

<script>
import TopNav from '@/components/Shared/TopNav.vue'
import Footer from '@/components/Shared/Footer.vue'

// import  CookieConsent from "v-cookie-consent";
// const CookieConsent = require('v-cookie-consent');



export default {
  components: {
    TopNav,
    Footer,
    // CookieConsent,

  }
}
</script>

<style>
p {
  padding-bottom: 0.5rem;
}
.router-link-active{
  background:red !important;
}
.portfolio-content b {
  color: #5da127;
  font-weight: bold;
}
h1 {
  color: #333;
}
</style>
