<template>
  <!--Header-->
    <div class="pt-12 grid justify-items-stretch" id="estimate">
      <!-- <div class="container px-3 mx-auto flex justify-center relative "> -->
        <div class="justify-self-center relative"> 
          <h1 class="text-3xl font-bold dark:text-gray-200 relative z-10">Estimate your project</h1>
          <span class="absolute w-120 h-3 bg-yellow-100 bottom-0.5 -ml-10 dark:bg-gray-600"></span>
      </div>
    </div>
    <!-- Card item -->
    <div class="pt-8 pb-10">
      <div class="container px-3 mx-auto flex flex-wrap flex-col md:flex-row items-start">
        <!--Left Col-->
        <div class="flex flex-col w-full md:w-6/12 justify-center items-start rounded-lg shadow-lg bg-white dark:bg-gray-800">
              <form class="w-full py-8 px-6" @submit.prevent="submit">
                    <div class="md:flex mb-6">
                        <div class="md:w-1/3">
                            <label class="block text-mygreen font-bold md:text-left mb-3 md:mb-0 pr-4" for="my-textfield">
                                Your name *
                            </label>
                        </div>
                        <div class="md:w-2/3">
                            <input class="form-input shadow rounded block w-full focus:shadow-md focus:outline-none h-8 dark:bg-gray-200" id="my-textfield" type="text" v-model="name" placeholder="" required>
                        </div>
                    </div>
                    <div class="md:flex mb-6">
                        <div class="md:w-1/3">
                            <label class="block text-mygreen font-bold md:text-left mb-3 md:mb-0 pr-4" for="my-textfield">
                                Company   
                            </label>
                        </div>
                        <div class="md:w-2/3">
                            <input class="form-input shadow rounded block w-full focus:shadow-md focus:outline-none h-8 dark:bg-gray-200" id="my-textfield" type="text" v-model="company" placeholder="">
                        </div>
                    </div>
                    <div class="md:flex mb-6">
                        <div class="md:w-1/3">
                            <label class="block text-mygreen font-bold md:text-left mb-3 md:mb-0 pr-4" for="my-textfield">
                                Your email *    
                            </label>
                        </div>
                        <div class="md:w-2/3">
                            <input class="form-input shadow rounded block w-full focus:shadow-md focus:outline-none h-8 dark:bg-gray-200" id="my-textfield" type="email" v-model="email" placeholder="" required>
                        </div>
                    </div>

                    <div class="md:flex mb-6">
                        <div class="md:w-1/3">
                            <label class="block text-mygreen font-bold md:text-left mb-3 md:mb-0 pr-4" for="my-select">
                                Type of project *
                            </label>
                        </div>
                        <div class="md:w-2/3">
                            <select name="" class="form-select block w-full rounded shadow h-8 focus:shadow-md focus:outline-none pl-3 dark:bg-gray-200" id="my-select" required>
                                <option value="Default">Select</option>
                                <option v-for="type in  projectType" :key="type" :value="type" @click="selectedProject = type">{{ type }}</option>
                            </select>

                        </div>
                    </div>

                    <div class="md:flex mb-6">
                        <div class="md:w-1/3">
                            <label class="block text-mygreen font-bold md:text-left mb-3 md:mb-0 pr-4" for="my-textarea">
                                Project description *
                            </label>
                        </div>
                        <div class="md:w-2/3">
                            <textarea class="form-textarea block w-full focus:shadow-md focus:outline-none shadow rounded dark:bg-gray-200" id="my-textarea" v-model="description" rows="5" required></textarea>
                        </div>
                    </div>

                    <div class="md:flex mb-4">
                        <div class="md:w-1/3">
                        </div>
                        <div class="md:w-2/3">
                            <div>
                                <label class="inline-flex items-center">
                                    <input type="checkbox" class="form-checkbox text-mygreen" unchecked required @change="checked = !checked">
                                    <span class="ml-2 dark:text-gray-50">I agree with <a href="/pages/privacy-policy" target="_blank" class="text-mygreen font-bold">privacy policy</a></span>
                                </label>
                            </div>
                        </div>
                    </div>

                    <div v-if="checked == true" class="md:flex mb-4">
                        <div class="md:w-1/3">
                        </div>
                        <div class="md:w-2/3">
                            <!-- <div class="g-recaptcha" data-sitekey="6LfIX5YcAAAAAJRMvk-1iMK5fnEYIfasEpYczn4U"></div> -->
                        </div>
                    </div>

                    <div class="md:flex md:items">
                        <div class="md:w-1/3"></div>
                        <div class="flex md:w-2/3 md:justify-end">

                        
                        
                        <!--    <button @click="recaptcha" -->
                            <button :class="`${!checked ? 'bg-green-300 cursor-not-allowed' : 'bg-green-500'} flex align-center text-white font-bold rounded-full py-2 px-12 shadow-lg focus:outline-none focus:shadow-outline transform transition hover:scale-105 duration-300 ease-in-out`">
                                <svg v-if="sending == true" class="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                        <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                                        <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                </svg>
                                Send
                            </button>
          
                        </div>
                    </div>
                </form>
        </div>
        <div class="flex flex-col w-full md:w-6/12 pt-8 sm:px-10">
            <div class="flex items-center">
                <span class="w-2/12 material-icons-outlined text-base text-mygreen pr-8">schedule</span>
                <h3 class="w-10/12 my-4 text-xl  text-dark font-bold dark:text-gray-200">
                        <span class="text-mygreen">We’ll be in touch within 48h</span><br>to talk about details
                </h3>
            </div>
            <div class="flex items-center">
                <span class="w-2/12 material-icons-outlined text-base text-mygreen">description</span>
                <h3 class="w-10/12 my-4 text-xl  text-dark font-bold dark:text-gray-200">
                        <span class="text-mygreen">We’ll send you costs and timeline in 3 days</span><br>after initial contact
                </h3>
            </div>
            <div class="flex items-center">
                <span class="w-2/12 material-icons-outlined text-base text-mygreen pr-8">forward_to_inbox</span>
                <h3 class="w-10/12 my-4 text-xl  text-dark font-bold dark:text-gray-200">
                        <span class="text-mygreen">Have a question? Get in touch via</span><br>projects[at]flexisoft.dev
                </h3>
            </div>
            
        </div>
      </div>
    </div>

<div v-if="showModal == true">
    <div class="fixed z-10 inset-0 overflow-y-auto" aria-labelledby="modal-title" role="dialog" aria-modal="true" @click="showModal = !showModal">
    <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        
        <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true"></div>

        
        <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>

       
        <div class="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
            <div class="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                <div class="sm:flex sm:items-start">
                <div class="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12  sm:mx-0 sm:h-10 sm:w-10">
                    <span class="w-2/12 material-icons-outlined text-base text-mygreen pr-8">schedule</span>
                </div>
                <div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                    <h3 class="text-lg leading-6 font-medium text-gray-900" id="modal-title">
                    Thank you for your message
                    </h3>
                    <div class="mt-2">
                    <p class="text-sm text-gray-500">
                        We will contact you as soon as possible to discuss your inquiry
                    </p>
                    </div>
                </div>
                </div>
            </div>
        </div>
    </div>
    </div>
</div>  


</template>
<script>
// import { useReCaptcha } from "vue-recaptcha-v3";

export default {
  name: 'EstimateProject',
  data() {
      return {
        checked: false,
        name: '',
        company: '',
        email: '',
        projectType: [
            'Mobile',
            'Web',
            'Web and mobile',
            'UX/UI',
            'Other services'
        ],
        selectedProject: '',
        description: '',
        data: '',
        sending: false,
        showModal: false,
      }
  },
//   setup() {
//     //   https://github.com/AurityLab/vue-recaptcha-v3
//     const { executeRecaptcha, recaptchaLoaded } = useReCaptcha()

    

// // Hide reCAPTCHA badge:
//     // recaptcha.hideBadge()
    

//     const recaptcha = async () => {
//       // (optional) Wait until recaptcha has been loaded.
//       await recaptchaLoaded()
        
//       // Execute reCAPTCHA with action "login".
//       const token = await executeRecaptcha('login')
//         console.log(token);
//       // Do stuff with the received token.
//     }

//     return {
//       recaptcha
//     }
//   },
  methods: {
    selected(type) {
          this.selectedProject = type
    }, 
    submit() {
        this.sending = true
        
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', 'Authorization': 'Token 7bad764b126c705e0514dd58d0b33b41cdc19a2c'},
            body: JSON.stringify({ name: this.name, company: this.company, email: this.email, type: this.selectedProject, description: this.description })
        };
        fetch("https://flexiback.flexisoft.usermd.net/estimate/", requestOptions)
        .then(async response => {
            const data = await response.json();

            // check for error response
            if (!response.ok) {
            // get error message from body or default to response status
                const error = (data && data.message) || response.status;
                return Promise.reject(error);
            } else {
                this.sending = false
                this.name = ""
                this.company = ""
                this.email = ""
                this.selectedProject = ""
                this.description = ""
                this.checked = false
                this.showModal = true
            }

            this.data = data;
        })
        .catch(error => {
            this.errorMessage = error;
            console.error('There was an error!', error);
        }); 
    },   
  }

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.material-icons-outlined {
    font-size: 46px;
}
</style>
