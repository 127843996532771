import { createApp } from 'vue'
import App from './App.vue'
import './assets/tailwind.css'
import router from './router'


import 'material-icons/iconfont/material-icons.css';




// import { VueReCaptcha } from 'vue-recaptcha-v3'
// For more options see below
// Vue.use(VueReCaptcha, { siteKey: '6LdwUWccAAAAAAL1cJGdoacRNsxDm4CPeNaj5cz6' }) //

createApp(App)
    .use(router)
    // .use(VueReCaptcha, { siteKey: "6LdwUWccAAAAAAL1cJGdoacRNsxDm4CPeNaj5cz6" }) // flexisoft.dev
    .mount('#app')
    
// import titleMixin from '@/mixins/titleMixin'
// const app = createApp({
//     extends: App,
//     mixins: [titleMixin],
//     router
// })
// app.use(router)

// app.mount('#app')