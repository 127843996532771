<template>
  <Disclosure as="nav" :class="`${scrollPosition > 100 ? 'shadow-lg fixed w-full transition delay-100 duration-300 ease-in z-50 bg-white dark:bg-gray-700' : 'fixed w-full'}`" v-slot="{ open }">
    <!-- <div class="container px-3 mx-auto flex flex-wrap flex-col md:flex-row items-center"> -->
    <!-- <div class="max-w-7xl mx-auto px-2 pt-2 sm:px-6 lg:px-8"> -->
    <div class="container mx-auto px-2 pt-2 sm:px-6 lg:px-6">
      <div class="relative flex items-center justify-between h-16">
        <div class="absolute inset-y-0 left-0 flex items-center sm:hidden">
          <!-- Mobile menu button-->
          <DisclosureButton class="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-green-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
            <span class="sr-only">Open main menu</span>
            <MenuIcon v-if="!open" class="block h-6 w-6 text-gray-500 dark:text-gray-100" aria-hidden="true" />
            <XIcon v-else class="block h-6 w-6" aria-hidden="true" />
          </DisclosureButton>
        </div>
        <div class="flex-1 flex items-center justify-center sm:items-stretch sm:justify-between">
        <!-- <div class="flex-1 flex items-center justify-center sm:items-stretch sm:justify-start"> -->
          <div class="flex-shrink-0 flex items-center">
            <a :href="navigation[0].href"><img class="block lg:hidden h-11 w-auto" src="@/assets/logo.svg" alt="Flexisoft" /></a>
            <div v-if="darkMode == true">
              <a :href="navigation[0].href"><img class="transform transition hover:scale-105 duration-200 hidden lg:block h-11 w-auto" src="@/assets/logo-with-white-text.svg" alt="Flexisoft" /></a>
            </div>
            <div v-else>
              <a :href="navigation[0].href"><img class="transform transition hover:scale-105 duration-200 hidden lg:block h-11 w-auto" src="@/assets/logo-with-text.svg" alt="Flexisoft" /></a>
            </div>
          </div>
          <div class="hidden sm:block sm:ml-6 sm:items-right sm:justify-end">
            <div class="flex space-x-7">
              <a v-for="item in navigation" :key="item.name" :href="item.href" :class="[$route.name == item.name || ($route.name == 'Service' && item.name == 'Services') ? 'bg-gray-100 text-dark focus:outline-none dark:text-dark dark:bg-gray-300' : 'text-dark transform transition hover:scale-105 duration-300 ease-in-out hover:bg-green-500 hover:text-white', 'px-3 py-2 rounded-md text-base font-bold dark:text-gray-50']" :aria-current="item.current ? 'page' : undefined">{{ item.name }}</a>
              <!-- <a v-for="item in navigation" :key="item.name" :href="item.href" :class="[$route.name == item.name || $route == 'item.name '? 'bg-gray-100 text-dark focus:outline-none dark:text-dark dark:bg-gray-300' : 'text-dark transform transition hover:scale-105 duration-300 ease-in-out hover:bg-green-500 hover:text-white', 'px-3 py-2 rounded-md text-base font-bold dark:text-gray-50']" :aria-current="item.current ? 'page' : undefined">{{ item.name }}</a> -->
            </div>
          </div>
        </div>
        <!-- dark mode -->
        <div class="absolute inset-y-0 right-0 flex items-center sm:static sm:inset-auto sm:ml-8 pr-2 sm:pr-0">
          <button type="button" class="transform transition hover:scale-105 duration-200 focus:outline-none">
            <span class="sr-only">Light mode</span>
              <div v-if="darkMode == true">
                <span class="material-icons-outlined text-gray-100" @click="changeTheme">light_mode</span>
              </div>
              <div v-else>
                <span class="material-icons-outlined text-gray-500 dark:text-gray-300" @click="changeTheme">dark_mode</span>
              </div>
              
              
          </button>
        </div>
        
      </div>
    </div>
    <!-- mobile -->
    <DisclosurePanel class="sm:hidden">
      <div class="px-2 pt-2 pb-3 space-y-1 z-50 bg-white shadow-lg dark:bg-gray-700 focus:outline-none"> 
        <!-- :class="[item.current ? -->
        <a v-for="item in navigation" :key="item.name" :href="item.href" :class="[$route.name == item.name || $route == item.name ? 'bg-gray-100 text-dark dark:text-dark dark:bg-gray-300' : 'text-dark hover:bg-green-500 hover:text-white', 'block px-3 py-2 rounded-md text-base font-bold dark:text-gray-50']" :aria-current="item.current ? 'page' : undefined">{{ item.name }}</a>
      </div>
    </DisclosurePanel>
  </Disclosure>
</template>

<script>
import { Disclosure, DisclosureButton, DisclosurePanel } from '@headlessui/vue'
// import { Disclosure, DisclosureButton, DisclosurePanel, Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue'
import { MenuIcon, XIcon } from '@heroicons/vue/outline'
// import { BellIcon, MenuIcon, XIcon } from '@heroicons/vue/outline'

const navigation = [
  { name: 'Home', href: '/', current: true },
  { name: 'Services', href: '/services', current: false },
  { name: 'About', href: '/about', current: false },
  { name: 'Contact', href: '/contact', current: false },
]
// :active='$route.name =="PlacesAll" || $route.name =="Place"'

export default {
  components: {
    Disclosure,
    DisclosureButton,
    DisclosurePanel,
    // Menu,
    // MenuButton,
    // MenuItem,
    // MenuItems,
    // BellIcon,
    MenuIcon,
    XIcon,
  },
  setup() {
    return {
      navigation,
    }
  },
  data() {
    return {
      scrollPosition: null,
      darkMode: false,
      userTheme: '',
    }
  },
  mounted() {
    window.addEventListener('scroll',()=> this.scrollPosition = window.scrollY)
    // document.documentElement.className = 'dark';
    // this.changeTheme()
    
    const savedUserTheme = localStorage.getItem('user-theme')
    if (savedUserTheme == 'dark') {
      this.darkMode = true
    } else {
      this.darkMode = false
    }
    this.setTheme(savedUserTheme)
  },
  methods: {
    changeTheme() {
      this.darkMode = !this.darkMode
      if (this.darkMode == true) {
        this.setTheme("dark");
      } else {
        this.setTheme("");
      }
    },

    setTheme(theme) {
      localStorage.setItem("user-theme", theme);
      this.userTheme = theme;
      // setTimeout(() => document.documentElement.className = theme, 300);
      document.documentElement.className = theme;
    },
  }
}
</script>

<style>
  .router-link-active{
  background:red !important;
}
</style>