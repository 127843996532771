
<template>
  <!--Header-->
    <div class="pt-12 grid justify-items-stretch">
      <!-- <div class="container px-3 mx-auto flex justify-center relative "> -->
        <div class="justify-self-center relative"> 
          <h1 class="text-3xl font-bold z-10 relative dark:text-gray-200">Who we are</h1>
          <span class="absolute w-120 h-3 bg-yellow-100 bottom-0.5 -ml-10 dark:bg-gray-600"></span>
      </div>
    </div>
    <!-- Card item -->
    <div class="pt-8">
      <div class="container px-3 mx-auto flex flex-wrap flex-col md:flex-row items-center">
        <div class="hidden md:flex flex flex-col w-full lg:w-3/12 xl:w-2/12 py-1 pr-2 justify-start items-start">
            <img src="@/assets/logo-big.png" alt="img" class="w-20 md:w-44 lg:w-44 cursor-pointer transform transition hover:scale-105 duration-200">
        </div>
        <div class="flex flex-col w-full lg:w-9/12 xl:w-8/12 justify-center items-start">
               <p class="font-light text-dark dark:text-white" v-html="content">
              </p>
              <div class="flex flex-col w-full pt-3">
                <b class="text-mygreen">{{ techTitle }}</b>
                
                <div class="flex flex-col md:flex-row md:space-x-6 pt-2">
                  <div v-for="part in techIcons" :key="part" class="flex items-center">
                        <span class="material-icons-outlined text-base text-mygreen">{{ part.value.icon}}</span>
                        <span class="font-normal dark:text-white"> {{ part.value.header }}</span>
                  </div>
                </div>
              </div>
              
              <div class="flex w-full justify-end pt-6">
                  <button class="flex items-center transform transition hover:scale-105 duration-200">
                      <a class="font-semibold text-mygreen dark:text-green-500" href="/about">learn more</a>
                      <span class="material-icons-outlined text-base text-mygreen dark:text-green-500">arrow_right_alt</span>
                  </button>
              </div>
              
          
        </div>
        
      </div>
    </div>
  

</template>

<script>
export default {
  name: 'WhoWeAre',
  props: [ 'content', 'techTitle', 'techIcons']
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
